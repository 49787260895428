import * as React from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import * as styles from "./Button.module.css";

type Props = {
  isPrimary?: boolean;
  to: string;
  children: React.ReactNode;
};

export const Button = (props: Props) => {
  return (
    <Link
      to={props.to}
      className={classnames([styles.button, props.isPrimary && styles.primary])}
      target="_blank"
    >
      {props.children}
    </Link>
  );
};
