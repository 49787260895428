import * as React from "react";
import {
  BlogContent,
  BlogHeading,
  Heading1,
} from "../components/Typography/Typography";
import { Container } from "../components/Container";
import { Button } from "../components/Button/Button";
import { Image } from "../components/Image/Image";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const july2003 = [
  `My mom hopped in our black minivan clutching on to her new camcorder.`,
  `She ripped open a fresh tape, and took a sharpie to the label.
   She wrote “Mateo's 6th Birthday Trip.”`,
  `The tape clicked in and the red light went on. All the way to Disney and back.`,
  `I couldn't understand why my mom was obsessed with being the family director until we made it home.`,
  `She told us to sit on the couch for the debut of our home movie. 
   We did as she asked despite the suspicion that we could not be entertained by watching memories we had just made. `,
  `Yet we spent the next hour laughing and restarting some of our arguments caught on film.
   And after that day, something strange happened in our home. Our camcorder went missing.`,
  `You see my dad just had to capture the game days for his volleyball league. 
   My sister was determined to video journal her teenage romance. 
   And yes, I stole it myself a few times to tape episodes of the neighbor's cat & dog exploring our backyard. 
   We all had our own tapes, closely guarded by their respective owners.`,
  `On Labor Day weekend it all came together. The tape read “Tia Vicky's Party”. `,
  `My aunt was moving back to Ecuador and we threw a party in our backyard—bouncy house for the kids, 
   gossip circles for the moms, & Modelos for the dads.`,
  `My mom started the recording with the opening act of my dad as the grillmaster, 
   but as the day went on the camera bounced around.`,
  `I recorded my sister and her friends giggling as they looked through their yearbook. 
   My sister caught me face planting in the bouncy house. My dad taped my mom bringing out her homemade cake. 
   Even our cousins & aunts & uncles joined in to offer confessionals as to how they were going to miss Tia Vicky.`,
  `It was the closest we could get to capturing how that day really looked and felt. 
   The memories weren't snapshots—they were living moving displays of genuine emotion. `,
  `When the audience is right, putting a camera in someone's face will cause them to reveal themselves—when the audience is wrong, 
   it causes them to lose themselves.`,
  `That is why (home) entertainment is the only real truth.`,
  `Press fast forward…`,
];
const september2022part1 = [
  `I could not be more blessed to formally announce what we've been working on over the last year.`,
  `Chris, Aditya and I quit our jobs in the spring of 2021 to build social software. 
   We came from experiences at Twitter, Facebook, Citi & Cockroach Labs.`,
];
const september2022part2 = [
  `Collie was a collaborative summer bucket list maker for high schoolers. 
   We were able to acquire a large amount of users in June 2021. 
   Even thought we celebrated the growth at first, reality quickly set in when we looked at the retention. 
   People may like your app enough to download it and sign up, but how many really turn it in to a habit? And just how positive of a habit is it in their lives?`,
  `At the end of the summer we had our answer. 
   There was still a loyal group using the app for hours & hours a week. 
   They were obsessed with using it to create a private shared journal of their summer together. 
   Like a digital scrapbook.`,
  `So we went back to the drawing board in the fall of 2021 with what we had learned. 
   Clearly, there was a craving for a low stakes space for friends to craft stories together.`,
  `We knew we wanted to build something that was private first, something that was multiplayer first—and thinking back to those childhood home movies, 
   we knew it had to be video first.`,
];
const september2022part3 = [
  `I'm immensely grateful to announce that Studio has closed a seed round led by GV's M.G. Siegler with participation from Mercury Fund, Pareto and 
   incredible angels like Spencer Kimball, Sahil Lavingia, Scott Belsky, Robert Andersen, Mike Mignano, Vicente Zavarce, Kevin Carter, Darren Lachtman, 
   the Black Angel Group, Charlie Cheever, and more.`,
  `I'm proud of how far we've made it as just a small founding team—but we're ready to grow.`,
  `We're looking for a founding engineer, and a founding designer—people who build from the heart, people who are excited about innovating the future of video, 
   and people who believe software is art. `,
  `There is no doubt in my mind that over the long run, products that have soul will win out. Now more than ever, people crave meaningfulness in their 
   experiences and apps have been falling short in delivering that.`,
  `That is why we consider ourselves so lucky to be on this journey, where everyday we get to build a product that makes us and many young people 
   across the US feel fulfilled. It starts and ends with our pure intentions.`,
];
const ending = [
  `I'm happy to let you know that my family has traded the camcorder for our app.`,
  `My parents are still recording their trips, as their Savannah vacation special rings fresh in my mind. My sister has a family of her own now, and my niece's 
   first day of 5th grade was a big episode. This time, I'm the one throwing the parties and getting everyone to record memories they'd probably forget (or might want to).`,
  `Everyone has a story to tell. All you have to do is hit record and press play.`,
];

const container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  overflowX: "hidden",
};
const contentContainer = {
  display: "flex",
  flexDirection: "column",
  marginTop: 50,
  marginBottom: 25,
  width: "85%",
  maxWidth: 750,
};
const heading = {
  margin: "50px",
  fontSize: "80px",
  fontFamily: "raptor",
  fontWeight: 900,
  textAlign: "center",
  letterSpacing: "-0.3rem",
};

export const BlogPage = () => {
  return (
    <Layout>
      <div style={container}>
        <h1 style={heading}>press play</h1>
        <div style={{ minWidth: 300, maxWidth: 325 }}>
          <a href="/studio-launch-poster.png" target="_blank">
            <Image src="/studio-launch-poster.png" alt="poster" />
          </a>
        </div>
        <div style={contentContainer}>
          <BlogHeading>
            {"July 2003. Corona, Queens. The City of New York."}
          </BlogHeading>
          <BlogContent style={{ marginBottom: 24, fontWeight: 600 }}>
            {"The summer I fell in love with (home) movies."}
          </BlogContent>

          <BlogContent
            style={{ marginBottom: 24, fontWeight: 400, fontStyle: "italic" }}
          >
            “If you want to see what someone fears losing, watch what they
            record.”
          </BlogContent>

          {july2003.map((text, index) => {
            return (
              <div key={index} style={{ marginBottom: 24 }}>
                <BlogContent>{text}</BlogContent>
              </div>
            );
          })}

          <BlogHeading>
            {"September 2022. Long Island City, Queens. The City of New York."}
          </BlogHeading>
          <BlogContent style={{ marginBottom: 24, fontWeight: 600 }}>
            {"The fall we reminded everyone of the magic of (home) movies."}
          </BlogContent>

          {september2022part1.map((text, index) => {
            return (
              <div key={index} style={{ marginBottom: 24 }}>
                <BlogContent>{text}</BlogContent>
              </div>
            );
          })}

          <BlogContent style={{ marginBottom: 24 }}>
            <Link
              target={"_blank"}
              to="https://500ish.com/be-willing-to-wait-for-it-556866a934ea"
              activeClassName="active"
              style={{ textDecoration: "underline rgba(0, 0, 0, 0.2)" }}
            >
              {"One of our first apps, Collie taught us the most valuable lesson we've learned " +
                "thus far—building a good product is much more than prioritizing growth."}
            </Link>
            {" It's about taking pride in your craftsmanship. It's about building from a set of " +
              "shared values so that the work you put out in the world can stand the test of time."}
          </BlogContent>

          {september2022part2.map((text, index) => {
            return (
              <div key={index} style={{ marginBottom: 24 }}>
                <BlogContent>{text}</BlogContent>
              </div>
            );
          })}

          <BlogContent style={{ marginBottom: 24 }}>
            {
              "So we set out to build a new app, which we called a group camcorder. "
            }
            <Link
              target={"_blank"}
              to="https://apps.apple.com/us/app/studio-the-group-camcorder/id1582843261"
              activeClassName="active"
              style={{ textDecoration: "underline rgba(0, 0, 0, 0.2)" }}
            >
              {"You can download it here."}
            </Link>
            {" And now we're fortunate enough to have investors onboard that align with our vision " +
              "and most importantly, our principles."}
          </BlogContent>

          {september2022part3.map((text, index) => {
            return (
              <div key={index} style={{ marginBottom: 24 }}>
                <BlogContent>{text}</BlogContent>
              </div>
            );
          })}

          {ending.map((text, index) => {
            return (
              <div key={index} style={{ marginBottom: 24 }}>
                <BlogContent>{text}</BlogContent>
              </div>
            );
          })}

          <Link to="/team" style={{ textDecoration: "none", maxWidth: 300 }}>
            <BlogContent style={{ fontWeight: 600 }}>
              With love,
              <br />
              <br />
              <img width={120} src="./matt-signature.png" />
              <br />
              <br />
              Matt Hidalgo
              <br />
              Cofounder & CEO
              <br />
              <u>Studio of America, Inc.</u>
            </BlogContent>
          </Link>

          <BlogContent
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              marginTop: 20,
              marginBottom: 40,
            }}
          >
            P.S. "Guard your heart above all else, for it determines the course
            of your life.
          </BlogContent>
        </div>

        <Container>
          <Button isPrimary to="/">
            home
          </Button>
        </Container>
      </div>
    </Layout>
  );
};

export default BlogPage;

function joinWith<T>(items: T[], separator: T): T[] {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (i !== items.length - 1) {
      result.push(items[i], separator);
    } else {
      result.push(items[i]);
    }
  }

  return result;
}
